import React, {useEffect, useState} from 'react';
import '../style/Main.css';

const Calculator = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const weddingUnix = 1726905600000;
    const weddingDate = new Date(weddingUnix);

    let happened = currentDate >= weddingDate;

    const secondsLeft = Math.floor((weddingDate - currentDate) / 1000);
    const minutesLeft = Math.floor(secondsLeft / 60);
    const hoursLeft = Math.floor(minutesLeft / 60);
    const daysLeft = Math.floor(hoursLeft / 24);
    const weeksLeft = Math.floor(daysLeft / 7);

    const displayWeeks = weeksLeft;
    const weekSeconds = displayWeeks * 7 * 24 * 60 * 60;
    const displayDays = Math.floor((secondsLeft - weekSeconds) / 60 / 60 / 24);
    const daysSeconds = displayDays * 24 * 60 * 60;
    const displayHours = Math.floor((secondsLeft - weekSeconds - daysSeconds) / 60 / 60);
    const hoursSeconds = displayHours * 60 * 60;
    const displayMinutes = Math.floor((secondsLeft - weekSeconds - daysSeconds - hoursSeconds) / 60);
    const minutesSeconds = displayMinutes * 60;
    const displaySeconds = secondsLeft - weekSeconds - daysSeconds - hoursSeconds - minutesSeconds;

    useEffect(() => {
        const interval = setInterval(() => setCurrentDate(new Date()), 1000);

        return () => {
            clearInterval(interval);
        };
    }, [])


    return (<>
        {!happened && ( <div className="calculator-container">
            <div className="calculator-date">
                <div className="calculator-item">
                    <span className="calculator-display">{displayWeeks}</span>
                    <span>Недель</span>
                </div>
                <div className="calculator-item">
                    <span className="calculator-display">{displayDays}</span>
                    <span>Дней</span>
                </div>
                <div className="calculator-item">
                    <span className="calculator-display">{displayHours}</span>
                    <span>Часов</span>
                </div>
                <div className="calculator-item">
                    <span className="calculator-display">{displayMinutes}</span>
                    <span>Минут</span>
                </div>
                <div className="calculator-item">
                    <span className="calculator-display">{displaySeconds}</span>
                    <span>Секунд</span>
                </div>
            </div>
        </div>)
        }
    </>);
};

export default Calculator;