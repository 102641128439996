import React, {useState} from 'react';
import emailjs from 'emailjs-com';

const Email = () => {
    const [selectedValue, setSelectedValue] = useState('option1')

    const handleRadioChange = (e,change) => {
        e.stopPropagation();
        setSelectedValue(change);
    }

    function sendEmail(e) {
        e.preventDefault();

        setTimeout(() => {
            emailjs.sendForm('service_d0k1ryo', 'template_ht27b7b', e.target, 'iUU36VO0dZ847gKpl')
                .then((result) => {
                    window.location.reload()
                }, (error) => {
                    console.log(error.text);
                });
        }, 1000)
    }



    return (
        <form className="contact-form" onSubmit={sendEmail}>
            <input className="form-input" type="text" name="from_name" placeholder="Ваше имя*" required/>
            <input className="form-input" type="text" name="from_second_name" placeholder="Ваша фамилия*" required/>
            <div>
                <div onClick={(e) => handleRadioChange(e,"option1")}>
                    <input
                        type="radio"
                        id="option1"
                        value="option1"
                        checked={
                            selectedValue ===
                            "option1"
                        }
                        onChange={(e) => handleRadioChange(e,"option1")}
                    />
                    <label htmlFor="option1" style={{fontWeight: selectedValue === 'option1' ? 'bold' : 'normal'}}>Приду один</label>
                </div>
                <div onClick={(e) => handleRadioChange(e,"option2")}>
                    <input
                        type="radio"
                        id="option2"
                        value="option2"
                        checked={
                            selectedValue ===
                            "option2"
                        }
                        onChange={(e) => handleRadioChange(e,"option2")}
                    />
                    <label htmlFor="option2" style={{fontWeight: selectedValue === 'option2' ? 'bold' : 'normal'}}>Приду не один</label>
                </div>
            </div>
            {selectedValue === "option2" && (<>
                <input className="form-input" type="text" name="guest_name" placeholder="Имя гостя" required />
                <input className="form-input" type="text" name="guest_second_name" placeholder="Фамилия гостя" required />
            </>)}
            <label htmlFor="textarea" className="form-input">Комментарий (не обязательно):</label>
            <textarea id="textarea" name="form_message" />
            <div className="confirm-button-container">
                <button className="confirm-button" type="submit">Подтвердить</button>
            </div>
        </form>
    );
};

export default Email;