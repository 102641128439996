import './App.css';
import './style/Main.css'
import Calculator from "./components/Calculator";
import Email from "./components/Email";

function App() {
    const openNewWindow = (url) => {
        window.open(url, "_blank")
    }

  return (
    <div className="container">
        <div className="inner-container">
            <div className="image-bgr">
                <p className="p-together-because">Вместе и навсегда</p>
                <p className="p-1">Надежда & Евгений</p>
                <p className="p-2">БУДЕМ&nbsp;СЧАСТЛИВЫ&nbsp;РАЗДЕЛИТЬ ЭТОТ&nbsp;ДЕНЬ&nbsp;С&nbsp;ВАМИ</p>
                <p className="p-3">21 сентября 2024</p>
                <p className="p-4">СУББОТА, 15:00</p>
                <p className="p-5">ЖДЕМ ВАС!</p>
            </div>

            <Calculator />

            <div className="invitation-container">
                <div className="invitation">
                    <h2>Дорогой Гость!</h2>
                    <p style={{marginBottom: 35}}>Мы рады сообщить Вам, что 21.09.2024 состоится самое главное торжество в нашей жизни - день нашей свадьбы!
                        Приглашаем Вас разделить с нами радость этого незабываемого дня</p>
                    <p>
                        21.09.2024 в 15:00
                    </p>
                    <div className="wedding-img-container">
                        <img src="/nadya-i-zhenya.jpg" alt="Wedding-photo"/>
                    </div>
                    <p className="mon-amour">
                        Любовь не знает расстояний, Для нее не существует континентов, Ибо ее взор всегда устремлен к звездам!
                    </p>
                    <p className="your-presence">
                        Ваше присутствие в день нашей свадьбы - самый значимый подарок для нас!
                    </p>
                    <p className="food-menu">
                        Анкета
                    </p>

                    <Email />

                    <p className="waiting">
                        Ждем Вас!
                    </p>
                </div>
            </div>
            <div className="schedule-container">
                <p className="schedule-title">
                    Свадебное расписание
                </p>
                <div className="schedule">
                    <div>
                        <p>
                            15:00
                        </p>
                        <p>
                            21.09.2024
                        </p>
                    </div>
                    <div>
                        <p>
                            Выездная регистрация
                        </p>
                        <p>
                            Лофт COM.NATA
                        </p>
                        <p>
                            Приглашаем вас разделить вместе с нами радость создания новой семьи
                        </p>
                    </div>
                </div>
                <div className="schedule">
                    <div>
                        <p>
                            15:30
                        </p>
                        <p>
                            21.09.2024
                        </p>
                    </div>
                    <div>
                        <p>
                            Банкет
                        </p>
                        <p>
                            Лофт COM.NATA
                        </p>
                    </div>
                </div>
            </div>
            <div className="map-container">
                <div className="map" onClick={() => openNewWindow('https://yandex.ru/maps/2/saint-petersburg/house/gazovaya_ulitsa_10zh/Z0kYdA5jTUQHQFtjfXV3dnlhZA==/?ll=30.293410%2C59.967520&mode=search&sctx=ZAAAAAgAEAAaKAoSCZ5eKcsQz0JAEdOgaB7A4EtAEhIJ8bkT7L%2FOBkARNLkYA%2Bu47D8iBgABAgMEBSgKOABA1QFIAWImcmVsZXZfaXJyZWxfZmlsdGVyPWlycmVsX21hbnVhbF81MzQwMDdiNXJlYXJyPXNjaGVtZV9Mb2NhbC9HZW8vUG9zdGZpbHRlci9JcnJlbFRocmVzaG9sZD0wLjIwYjxyZWFycj1zY2hlbWVfTG9jYWwvR2VvL1Bvc3RmaWx0ZXIvSXJyZWxUaHJlc2hvbGRGb3JBZHM9MC4xMjViPXJlYXJyPXNjaGVtZV9Mb2NhbC9HZW8vUG9zdGZpbHRlci9EZWNpZGVUaHJlc2hvbGRCeVJ1YnI9ZmFsc2VqAnJ1nQHNzEw9oAEAqAEAvQGS1amCwgEZAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIICE9Cz0LDQt9C%2B0LLQsNGPIDEw0LaKAgCSAgCaAgxkZXNrdG9wLW1hcHM%3D&sll=30.309783%2C59.967029&sspn=0.101981%2C0.035022&text=%D0%B3%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%8F%2010%D0%B6&z=17.6')}>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
